.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane {
  height: 100%;
}

.ant-tab-with-overflow {
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
}

.tabs-without-padding {
  .ant-tabs-tab {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-tabs-tabpane {
    padding-left: 24px !important;
  }
}

.research-tab div {
  color: var(--white-on-white-100, #000);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.work-list-tab div {
  color: var(--white-on-white-100, #000);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}