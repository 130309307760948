.form-item {
  .label {
    color: var(--white-on-white-100, #000);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}

.input-validation-error-label {
  color: var(--red, #de3730);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: -0.24px;
  margin-top: 4px;
}

.compact-form {
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
}

.ant-form-item-label > label {
  font-weight: 500;
}

.gray-label-form {
  .ant-form-item-label > label {
    color: #626a6a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .ant-form-item-control-input-content {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
