.custom-input--wrapper {
  border-radius: 8px;
  border: 1px solid var(--divider, #d4e2e1);
  background: #fff;
  width: 100%;
  display: flex;
  padding: 2px;
  align-items: center;
  .ng-select-container {
    border: none;
  }

  .ant-input-number {
    border: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .suffix {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #F5F5F5;
      border-radius: 4px;
    }
  }

  .ant-picker {
    border: none;
  }

  .suffix-btn {
    border: none;
    background: #F5F5F5;
    border-radius: 4px;
  }

  .ant-input {
    border: none;
  }

  &:first-child {
    flex: 1;
  }
}
.custom-input--wrapper.borderless {
  border: none;
}

.custom-input--input {
  flex-grow: 1;
}

.custom-input--invalid {
  border: 1px solid var(--red, #de3730);
}
.custom-input--action {
  margin: 2px;
  border-radius: 4px;
  background: #f5f5f5;
  border: none;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.options-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;

  border-radius: 6px;
  border: 1px solid var(--divider, #d4e2e1);
  background: var(--White-White, #fff);
  box-shadow: 0px 4px 12px 0px rgba(38, 51, 77, 0.1);
  max-height: 300px;
  overflow-y: scroll;
}

.option {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid var(--divider, #d4e2e1);
  &:hover {
    background: var(--white-on-white-20, #f5f5f5);
    cursor: pointer;
  }

  color: var(--white-on-white-80, #626a6a);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
