.truncate-overflow-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ml-3 {
  margin-left: 3px;
}

.mt-3 {
  margin-top: 3px;
}
.mr-3 {
  margin-right: 3px;
}

.ml-5 {
  margin-right: 5px;
}

.m-0 {
  margin: 0;
}
.mt-5 {
  margin-top: 5px;
}
.pull-right {
  margin-left: auto;
}

.dotted-underline {
  border-bottom: 1px dotted;
}
@media screen and (max-width: 1440px) {
  .tablet--hide {
    display: none;
  }
}

@media screen and (max-width: 740px) {
  .mobile--hide {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .pc--hide {
    display: none;
  }
}

.component--round-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 7px;
}
