.steps--default-style {
  max-width: 340px;

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #d9d9d9;
    height: 2px;
    border-radius: 2px;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #36A8A0;
    background: #fff;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #36A8A0;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #16a34a;
    background: #16a34a;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
  }
}