.document-input {
  .ant-input {
    border: none;
    border-bottom: 1px dashed #d9d9d9;
  }

  .ant-input-number {
    border: none;
    border-bottom: 1px dashed #d9d9d9;
  }

  .ant-select {
    .ant-select-selector {
      border: none;
      border-bottom: 1px dashed #d9d9d9;
    }
  }

  .ant-picker {
    border: none;
    border-bottom: 1px dashed #d9d9d9;
  }
}
