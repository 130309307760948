.form-layout-with-closable-filters {
  .filters-container {
    border-radius: 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;

    .header {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid #d4e2e1;

      .title {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .parameters-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: auto;
    }

    .parameters {
      display: flex;
      padding: 0 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &.parameters-group {
        padding: 0;
        gap: 8px;
      }

      &.periods-group {
        gap: 8px;
      }

      .title {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-transform: uppercase;
        align-self: stretch;
      }

      .period-inputs-layout {
        display: flex;
        gap: 8px;

        .period-item {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

      .period-title {
        color: #626a6a;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .reset-btn {
      display: flex;
      padding: 8px 16px;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
      align-self: stretch;
      border-top: 1px solid #d4e2e1;
    }
  }

  .list-container {
    overflow-y: auto;
  }

  .closed-filters-form {
    display: flex;
    padding: 16px;
    align-items: center;
  }

  .status {
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;

    &.inProcess {
      width: 100px;
      color: #36a8a0;
      background: rgba(54, 168, 160, 0.20);
    }

    &.finished {
      width: 85px;
      color: #626a6a;
      background: rgba(172, 172, 172, 0.20);
    }

    &.planned {
      width: 121px;
      color: #065ad9;
      background: rgba(6, 90, 217, 0.10);
    }

    &.enteredInError {
      width: 77px;
      color: #dd2922;
      background: rgba(221, 41, 34, 0.10);
    }
  }
}
