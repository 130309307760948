@import 'colors';
@import 'app-header';
@import 'layout';
@import 'modal';
@import 'button';
@import 'notification';
@import './record/scale-selector';
// @import './fonts/manrope';
// @import './fonts/inter';
@import 'tab';
@import 'work-list/work-list';
@import 'dropdown';
@import 'message';
@import 'prescriptions/prescriptions-template';
@import 'input';
@import 'form';
@import 'popover';
@import 'text';
@import "select";
@import "radio";
@import "table";
body {
  font-family: 'Inter';
  font-size: 12px;
}
