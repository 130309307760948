.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background: #36A8A0;

  span:last-child {
    svg {
      path {
        fill: white;
      }
    }
  }
}
