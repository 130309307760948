.rais--record--scale-selector {
  .ant-radio-group {
    border-radius: 4px;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: none;
    border-radius: 4px 0 0 4px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
    border-right: none;
  }

  .ant-radio-button-wrapper {
    border: none;
    background: #f5f5f5;
    color: #929696;
    padding: 0px 16px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: white;
    background: #36A8A0;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    top: 5px;
    height: 50%;
    background-color: white;
  }
}

