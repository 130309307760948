.rais-select {
  .ant-select-selector {
    border-radius: 8px !important;
  }

  .ant-select-borderless {
    background: #f5f5f5 !important;
  }

  .ant-select {
    border-radius: 8px !important;
  }
}

.horizontal-select {
  .cdk-virtual-scroll-content-wrapper {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

}

.row-count-3 {
    .ant-select-item-option {
      flex: 1 0 25%;
    }
  }

.ant-select-disabled .ant-select-arrow {
  display: none;
}

.ant-select-disabled .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 0px !important;
}
