.ant-popover-title {
  color: white;
  border-bottom: 1px solid #f0f0f0;
  background: #343d3c;
  border-radius: 8px 8px 0px 0px;
}

.ant-popover-inner {
  border-radius: 8px;
}
