@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-BlackItalic.woff2') format('woff2'),
    url('./FiraSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-ThinItalic.woff2') format('woff2'),
    url('./FiraSans-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-SemiBold.woff2') format('woff2'),
    url('./FiraSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-Thin.woff2') format('woff2'),
    url('./FiraSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-Bold.woff2') format('woff2'),
    url('./FiraSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-ExtraLight.woff2') format('woff2'),
    url('./FiraSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-BoldItalic.woff2') format('woff2'),
    url('./FiraSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-Medium.woff2') format('woff2'),
    url('./FiraSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-ExtraBoldItalic.woff2') format('woff2'),
    url('./FiraSans-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-Light.woff2') format('woff2'),
    url('./FiraSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-ExtraBold.woff2') format('woff2'),
    url('./FiraSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-MediumItalic.woff2') format('woff2'),
    url('./FiraSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-Regular.woff2') format('woff2'),
    url('./FiraSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-SemiBoldItalic.woff2') format('woff2'),
    url('./FiraSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-ExtraLightItalic.woff2') format('woff2'),
    url('./FiraSans-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-LightItalic.woff2') format('woff2'),
    url('./FiraSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src:
    url('./FiraSans-Italic.woff2') format('woff2'),
    url('./FiraSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
