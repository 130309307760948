@import './colors';
.ant-message-notice-content {
  display: inline-block;
  padding: 14px 24px;
  background: @rais--general-white;
  border-radius: 8px;
  box-shadow: none;
  pointer-events: all;
  background: @rais--general-black;
  color: white;
  border-color: white;
}

.ant-message {
  top: 42px !important;
}
