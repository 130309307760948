.events-timeline-item--base(@color) {
  border-radius: 3px;
  &.events-timeline-item {
    .line,
    .dot {
      background: @color;
    }
  }
  &.events-timeline-item__moment {
    .events-timeline-item--label,
    .events-timeline-item--time {
      color: @color;
    }
    .events-timeline-auto-item--label,
    .events-timeline-auto-item--time {
      color: var(--white-on-white-80, #D97706);
    }
  }
  &.events-timeline-item__duration {
     background: fadeout(@color, 70%);
    color: @color;
  }
}

.planned-base {
  border-radius: 3px;
  &.events-timeline-item {
    .line,
    .dot {
      background: @blue-5;
    }
  }
  &.events-timeline-item__moment {
    .events-timeline-item--label,
    .events-timeline-item--time {
      color: @blue-5;
    }
  }
  &.events-timeline-item__duration {
     background: fadeout(@blue-3, 70%);
    color: @blue-5;
  }
}

.submitted-base {
  border-radius: 3px;
  &.events-timeline-item {
    .line,
    .dot {
      background: @green-7;
    }
  }

  &.events-timeline-item__duration {
    background: fadeout(@green-3, 70%);
    color: @green-7;
  }

  &.events-timeline-item__moment {
    .events-timeline-item--label,
    .events-timeline-item--time {
      color: @green-7;
    }
  }
}
