.work-list-prescription-row {
  .submit-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
  }
}

.on-hold-icon {
  .rais-icon {
    background: #a85c36;
    border-radius: 4px;
  }
}

.work-list-parameters-table {
  .ant-table-cell {
    padding: 4px;
  }
}

.work-list-parameter-value-input {
  .ant-input-number {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
  }
  .ant-input-number-input {
    text-align: center;
  }
  .ant-input {
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }
}
