.with-dark-scrollbar {
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #8c8c8c;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #262626;
    border-radius: 10px;
    border: #0a989c 3px solid;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &--thin {
    ::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #8c8c8c;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #262626;
      border-radius: 10px;
      border: #8c8c8c 3px solid;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.with-default-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    height: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e6f5f5;
    border-radius: 8px;
    opacity: 0.8;
    border: #0a989c 1px solid;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }
}
