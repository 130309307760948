.with-narrow-padding {
  padding: 5px;
}
.with-bottom-border {
  border-bottom: 1px #ebedf0 solid;
}

.with-right-border {
  border-right: 1px #ebedf0 solid;
}

.with-left-border {
  border-left: 1px #ebedf0 solid;
}
