@import './rais--vis-timeline-item.common.less';

@instr-researches-order--prescribed-color: @blue-5;
@instr-researches-order--active-color: #ffb811;
@instr-researches-order--rejected-color: #f63a3a;
@instr-researches-order--expired-color: #f63a3a;
@instr-researches-order--canceled-color: #ffababff;
@instr-researches-order--completed-color: @green-7;

.vis-item {
  .events-timeline-item--instr-researches-order {
    &.prescribed {
      .events-timeline-item--base(@instr-researches-order--prescribed-color);
    }

    &.active {
      .events-timeline-item--base(@instr-researches-order--active-color);
    }

    &.rejected {
      .events-timeline-item--base(@instr-researches-order--rejected-color);
    }

    &.expired {
      .events-timeline-item--base(@instr-researches-order--expired-color);
    }

    &.cancelled {
      .events-timeline-item--base(@instr-researches-order--canceled-color);
    }

    &.completed {
      .events-timeline-item--base(@instr-researches-order--completed-color);
    }

    &.sending-to-his {
      .events-timeline-item--base(@instr-researches-order--active-color);
    }

    &.sent-to-his {
      .events-timeline-item--base(@instr-researches-order--active-color);
    }

    &.rejected-by-his {
      .events-timeline-item--base(@instr-researches-order--rejected-color);
    }
  }
}
