@import './rais--vis-timeline-item.common.less';
@import 'rais--vis-timeline-item.actual-event.less';
@import 'rais--vis-timeline-item.laboratory-referral.less';
@import 'rais--vis-timeline-item.researches-orders.less';
@import 'rais--vis-timeline-item.instrumental-researches-orders.less';
.rais--timeline {
  .vis-item .vis-item-content {
    padding: 0;
    display: block !important;
  }

  .vis-item {
    .vis-drag-center {
      z-index: -1;
    }
  }

  .vis-item {
    background: none;
  }

  .vis-delete {
    display: none;
  }

  .vis-range {
    border: none !important;
  }

  .vis-item.vis-selected {
    background: none;
    border: 1px dashed orange;
    z-index: 1;
  }
  .vis-dot {
    display: none;
  }
  .vis-item.vis-point.vis-selected {
    background: none;
    border: 1px dashed orange;
  }
  .vis-item.vis-range.vis-selected {
    background: none;
    border: 1px dashed orange !important;
  }
}

.vis-selected {
  .events-timeline-item {
    border: 1px blue solid;
  }
}
.events-timeline-item {
  min-height: 23px;
  display: flex;
  cursor: pointer;
  &__duration {
    flex-direction: column-reverse;
  }
  &--label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
    .icon {
      transition: all 0.3s ease;
      margin-right: 10px;
      font-size: 16px;
      cursor: pointer;
    }
    .icon:hover {
      transform: scale(1.2) translateY(-3px);
    }
  }
}

.events-timeline-item.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.line {
  background: black;
  display: flex;
  flex-direction: row;
  &.horizontal {
    height: 2px;
    width: calc(100% + 8px);
    position: relative;
    right: 4px;
    justify-content: space-between;
    .dot {
      position: relative;
      bottom: 3px;
    }
  }
  .dot {
    position: relative;
    height: 8px;
    width: 8px;
    border-radius: 100%;
  }
  &.vertical {
    flex-direction: column;
    width: 2px;
    justify-content: flex-end;
    .dot {
      position: relative;
      right: 3px;
      top: 5px;
    }
    + .main {
      // color: #a9b7c6;
      display: -webkit-inline-box;
    }
  }

  &.boluse {
    flex-direction: column;
    width: 0px;
    justify-content: flex-end;
    .dot {
      position: relative;
      right: 3px;
      top: 5px;
    }
    + .main {
      // color: #a9b7c6;
      display: -webkit-inline-box;
    }
  }
}

.dropdown-element {
  font-size: 14px;
  transition: 150ms linear;
}

.dropdown-element:hover {
  font-size: 18px;
  transition: 150ms linear;
}
