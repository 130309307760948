@rais--timeline-group-background: #eef0f0;

.rais--timeline--white {
  .vis-panel.vis-left {
    .vis-label.vis-group-level-0 {
      background-color: @rais--timeline-group-background !important;
    }

    .vis-label.vis-nesting-group {
      background-color: @rais--timeline-group-background !important;
    }
  }

  .vis-panel.vis-center {
    .rais--vis-group-separator.vis-group {
      background-color: @rais--timeline-group-background;
    }
  }

  .inactive {
    background-color: rgba(128, 128, 128, 0.08);
  }
}
