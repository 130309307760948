.side-panel {
  width: 296px;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #D4E2E1;
  transition: 500ms linear;

  .link {
    color: #262626;
  }

  &--item {
    margin: 5px 0;
    color: #262626;
    //text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    gap: 8px;
  }

  &--item.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  &--item.active {
    color: #08979c;
  }

  &--item:hover {
    color: #08979c;
  }

  .side-panel--department-select {
    padding: 24px;
    border-bottom: 1px solid #D4E2E1;
  }

  .side-panel--department-tree {
    padding: 24px 16px;

    flex-grow: 1;
    overflow-y: auto;
    background: white;
    border-top: 1px solid var(--divider, #D4E2E1);
  }

  .side-panel--shared-section {
    padding: 24px;
    border-top: 1px solid var(--divider, #D4E2E1);
  }
}

.side-panel.collapsed {
  width: 148px;
}


.reanimation-side-panel-room-select {
  margin-left: -10px;
  margin-bottom: 5px;
  .ant-select {
    color: #8c8c8c;

    &-open {
      .ant-select-selector {
        box-shadow: none;
        border: none; //1px dashed #8C8C8C;
      }
    }
    .ant-select-selector {
      background-color: #fff;
      border: none;

      &__placeholder {
        color: #8c8c8c;
      }
    }
  }

  .ant-select-open {
  }

  .ant-select-selection-item {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #1F2633;
    font-family: Manrope;
  }

  .ant-select-item {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #1F2633;
    font-family: Manrope;
  }
}

