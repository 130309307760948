@import './colors';

.ant-modal.no-padding {
  .ant-modal-body {
    padding: 0;
  }
}
.ant-modal-content {
  border-radius: 16px;
}

.ant-modal-header {
  background: #343d3c;
  border-bottom: none;
  border-radius: 16px 16px 0 0;
}

.ant-modal-title {
  color: @rais--general-white;
}

.ant-modal-close {
  color: @rais--general-white;
  &:hover {
    color: #a6a6a6;
  }
}

.ant-modal-footer .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  & > * {
    flex: 1;
  }

  &:only-child > * {
    flex: 0.5;
  }
  &:only-child {
    align-items: center;
  }
}

.modal-header--empty {
  height: 18px;
}

.ant-modal-footer {
  border-radius: 0 0 16px 16px;
}
