/* You can add global styles to this file, and also import other style files */
// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.compact.less';
@import '../node_modules/ng-zorro-antd/code-editor/style/entry.less';
@import 'styles/theme/__theme';

@primary-color: @rais--primary-color;
@calendar-item-active-bg: #e6f5f5;
@menu-item-active-bg: #c5dbd847;
@alert-info-bg-color: #c5dbd847;
@font-size-base: 12px;
@text-color: fade(@black, 65%);
@divider-color: rgba(0, 0, 0, 1%);

@import '../node_modules/@fontsource/inter/100.css';
@import '../node_modules/@fontsource/inter/200.css';
@import '../node_modules/@fontsource/inter/300.css';
@import '../node_modules/@fontsource/inter/400.css';
@import '../node_modules/@fontsource/inter/500.css';
@import '../node_modules/@fontsource/inter/600.css';
@import '../node_modules/@fontsource/inter/700.css';
@import '../node_modules/@fontsource/inter/800.css';
@import '../node_modules/@fontsource/inter/900.css';

@import '../node_modules/@fontsource/inter/cyrillic-100.css';
@import '../node_modules/@fontsource/inter/cyrillic-200.css';
@import '../node_modules/@fontsource/inter/cyrillic-300.css';
@import '../node_modules/@fontsource/inter/cyrillic-400.css';
@import '../node_modules/@fontsource/inter/cyrillic-500.css';
@import '../node_modules/@fontsource/inter/cyrillic-600.css';
@import '../node_modules/@fontsource/inter/cyrillic-700.css';
@import '../node_modules/@fontsource/inter/cyrillic-800.css';
@import '../node_modules/@fontsource/inter/cyrillic-900.css';

@import '../node_modules/@fontsource/inter/cyrillic-ext-100.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-200.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-300.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-400.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-500.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-600.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-700.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-800.css';
@import '../node_modules/@fontsource/inter/cyrillic-ext-900.css';


@import 'assets/fonts/FiraSans/stylesheet.css';
@import 'assets/fonts/Roboto/stylesheet.css';
@import 'styles/scrollbar';
@import 'styles/select';
@import 'styles/utils';
@import 'styles/form';
@import 'styles/calendar';
@import 'styles/observation';
@import 'styles/ck';
@import 'styles/spin';
@import 'styles/tabs';
@import 'styles/header';
@import 'styles/process-sidebar';
@import 'styles/medical-insertions-sidebar';
@import 'styles/fieldset';
@import 'styles/table';
@import 'styles/button';
@import 'styles/reanimation-side-panel';
@import 'styles/descriptions';
@import 'styles/card';
@import 'styles/monitoring';
@import 'styles/progress';
@import 'styles/checkbox';
@import 'styles/datepicker';
@import 'styles/input';
@import 'styles/vis-timeline/rais--vis-timeline';
@import 'styles/documents';
@import 'styles/document-view';
@import 'styles/collapse';
@import 'styles/drawer';
@import 'styles/list-with-closable-filters-form-layout';
@import 'styles/empty';
@import 'styles/steps';

.with-monospace-font {
  font-family: monospace;
}
.debug-label {
  color: @rais--debug-color;
  opacity: 0.8;
  font-style: italic;
  font-family: monospace;
}

.debug-red-bold-label {
  color: @rais--debug-color-red;
  font-weight: bold;
  font-size: 14px;
  transition: all ease-in-out 0.2;
  &:hover {
    scale: 130%;
  }
}

.debug-red-micro-label {
  color: @rais--debug-color-red;
  font-size: 9px;
  transition: all ease-in-out 0.2;
  &:hover {
    scale: 130%;
  }
}

.vis-label.vis-nesting-group.expanded:before {
  display: none;
}
fieldset.with--narrow-padding {
  p {
    margin: 0;
  }
}
.vis-custom-time.extracorporeal-circulation-start {
  pointer-events: none;
  background: red;
}

.vis-custom-time.extracorporeal-circulation-end {
  pointer-events: none;
  background: red;
}

.ant-divider-horizontal {
  border-top-color: rgba(0, 0, 0, 0.2) !important;
}

.fixed-width-addon-100 {
  .ant-input-group-addon {
    min-width: 150px !important;
  }
}

.no-hover {
  .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
    background: inherit;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: inherit;
  }
}

.test {
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

a.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.flex-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
  padding-top: 0;
}
iframe {
  border: none;
}

.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: block;
}

.minimal-header {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

rais-app-root {
  display: block;
}

.ant-page-header-heading-title {
  font-family: 'Roboto', serif;
}

.ant-page-header-heading-subtitle {
  font-family: 'Roboto', serif;
}

.ant-modal-header {
  padding: 11px 40px 11px 16px;
}

.ant-select-item-option {
  word-break: break-all;
  .ant-select-item-option-content {
    white-space: unset;
  }
}



.with-dark-scrollbar {
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #8c8c8c;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #262626;
    border-radius: 10px;
    border: #8c8c8c 3px solid;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

a.dark-link {
  background: #595959;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px 16px;
  border: 0;
  cursor: pointer;
}
.dropdown-container {
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 3px;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    li {
      padding: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      gap: 4px;
    }

    li:hover {
      background: #D4E2E1;
      font-weight: 600;
    }

    li:not(:last-child) {
      border-bottom: 1px solid #D4E2E1;
    }
  }
}

.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 9px;
}

.ant-select-dropdown-menu-item-group-title {
  padding: 0 5px;
}

.side-panel--rooms {
  .ant-select-selection {
    border: 0;
  }
}
.nz-dropdown {
  background: #fff;
}

.row {
  display: flex;
  margin: 10px 0;
}

.main-container {
  position: relative;
  left: 150px;
}

.container {
  padding: 0 25px;
}

nz-time-picker,
nz-date-picker,
input,
nz-select,
.ant-calendar-picker,
.ant-input-number {
  width: 100% !important;
}

.ant-dropdown {
  nz-select {
    min-width: 70px !important;
    // width: auto !important;
  }
}

html,
body,
.grid-container,
.vis-label {
  &.vis-nested-group {
    display: flex;
    align-items: center;
    background: none;

    .vis-inner {
    }
  }
}

.vis-loading-screen {
  display: none !important;
}


.department-tree {
  .ant-tree-switcher {
    background: #fff;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .ant-tree-treenode {
    align-items: center;
  }
}

html {
  font-weight: 500;
}

.laboratory-referral-switch {
  .ant-switch-checked {
    background-color: #DD2922 !important;
  }

  .ant-switch {
    background-color: #E0E1E1;
  }
}
