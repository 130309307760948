.monitoring-cell {
  .ant-descriptions-item-label {
    color: #f7a000;
  }
}

.monitoring-parameter-cell {
  white-space: nowrap;
  .name {
    font-size: 12px;
    text-align: center;
    color: #cac5c5;
  }
  .value {
    font-size: 16px;
    font-weight: bold;
    border: 1px solid gray;
    border-radius: 8px;
    padding: 3px;
    text-align: center;
    &--arterial-pressure {
      font-size: 14px;
    }
  }
  .value--outdated {
    opacity: 0.3;
  }
  .mu-name {
    font-size: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (min-width: 740px) {
  .col-md-2 {
    .monitoring-parameter {
      width: 50px;
    }
  }

  .col-md-4 {
    .monitoring-parameter {
      width: 75px;
    }
  }

  .col-md-6 {
    .monitoring-parameter {
      width: 90px;
    }
  }

  .col-xs-6.col-md-6 {
    .monitoring-parameter {
      width: 35px;
    }
  }
}
@media screen and (max-width: 740px) {
  .monitoring-parameter {
    width: 50px;
  }
  .monitoring-data {
    .value {
      font-size: 12px;
    }
  }
}
