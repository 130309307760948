.ck-toolbar {
  height: 40px;
}

.ck-content {
  max-height: calc(100% - 40px);
  overflow-y: auto;
}

.ck {
  p {
    margin: 0;
  }
}
