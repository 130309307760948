.form--items-without-margin {
  .row {
    // margin: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    line-height: inherit;
  }
}

.delete-icon {
  cursor: pointer;
  color: red;
}

.form--with-white-label {
  label {
    color: whitesmoke;
  }
}
