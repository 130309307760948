.vin-panel.vis-left {
  min-width: 100px;
}

.vis-panel.vis-left {
  width: 450px;
}

.vis-legend rect {
  opacity: 0;
}

.without-top-panel {
  .vin-panel.vis-top {
    display: none;
  }
}

.without-left-panel {
  .vis-panel.vis-left {
    display: none;
  }
}
