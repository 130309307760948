.prescriptions-list-template-table {
  .ant-picker-input > input {
    font-size: 12px;
    font-style: normal;
    color: #000;
    font-weight: 500;
    line-height: 12px;
  }

  .ant-picker-suffix {
    font-size: 12px;
    font-style: normal;
    color: #000;
    font-weight: 500;
    line-height: 12px;
  }

  .ant-btn {
    font-weight: 600;
  }
}

.prescriptions-list-template-table-div {
  .prescriptions-list-template-table {
    overflow: hidden auto;
    max-height: 600px;
    margin: 0 !important;
  }
}

.prescriptions-list-save-button {
  .ant-btn > span {
    margin-top: 3px;
  }
}
