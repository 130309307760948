.ant-tabs-tab {
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.ant-tabs.with-scroll {
  overflow: hidden;
  .ant-tabs-content-holder {
    overflow-y: scroll;
  }
}

.ant-tabs.full-height {
  height: 100%;
  .ant-tabs-content {
    height: 100%;
  }

  .tab-container {
    height: 100%;
  }
}
