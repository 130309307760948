@import './rais--vis-timeline-item.common.less';

@researches-order--prescribed-color: #677789;
@researches-order-item--prescribed-item-color: @blue-5;
@researches-order--active-color: #ffb811;
@researches-order--rejected-color: #f63a3a;
@researches-order--canceled-color: #ffababff;
@researches-order--completed-color: @green-7;

.vis-item {
  .events-timeline-item--researches-order {
    &.prescribed {
      .events-timeline-item--base(@researches-order--prescribed-color);
    }

    &.active {
      .events-timeline-item--base(@researches-order--active-color);
    }

    &.rejected {
      .events-timeline-item--base(@researches-order--rejected-color);
    }

    &.cancelled {
      .events-timeline-item--base(@researches-order--canceled-color);
    }

    &.completed {
      .events-timeline-item--base(@researches-order--completed-color);
    }

    &.sending-to-lis {
      .events-timeline-item--base(@researches-order--active-color);
    }

    &.sent-to-lis {
      .events-timeline-item--base(@researches-order--active-color);
    }

    &.rejected-by-lis {
      .events-timeline-item--base(@researches-order--rejected-color);
    }
  }

  .events-timeline-item--ordered-research {
    &.prescribed {
      .events-timeline-item--base(@researches-order-item--prescribed-item-color);
    }

    &.active {
      .events-timeline-item--base(@researches-order--active-color);
    }

    &.rejected {
      .events-timeline-item--base(@researches-order--rejected-color);
    }

    &.cancelled {
      .events-timeline-item--base(@researches-order--canceled-color);
    }

    &.completed {
      .events-timeline-item--base(@researches-order--completed-color);
    }

    &.sending-to-lis {
      .events-timeline-item--base(@researches-order--active-color);
    }

    &.sent-to-lis {
      .events-timeline-item--base(@researches-order--active-color);
    }

    &.rejected-by-lis {
      .events-timeline-item--base(@researches-order--rejected-color);
    }
  }
}
