.document-view-collapse {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border-radius: 8px;
    background: var(--white-on-white-20, #f5f5f5);

    color: var(--white-on-white-100, #000);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
