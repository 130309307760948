@rais--timeline--black-group-background: black;

.rais--timeline--black {
  .vis-group.rais--timeline-top-group-label {
    background-color: #0f0f0f;
  }

  .vis-panel.vis-left {
    .vis-label {
      .vis-inner {
        color: #f0f0f0 !important;
      }
    }
    .vis-label.vis-group-level-0 {
      background-color: @rais--timeline--black-group-background !important;
    }

    .vis-label.vis-nesting-group {
      background-color: @rais--timeline--black-group-background !important;
    }
  }

  .vis-panel.vis-center {
    .rais--vis-group-separator.vis-group {
      background-color: @rais--timeline--black-group-background !important;
    }
  }

  .inactive {
    background-color: rgba(128, 128, 128, 0.08);
  }

  .ant-tag-processing {
    color: black;
    background: #91d5ff;
    border-color: #1890ff;
  }
  .ant-tag-red {
    color: black;
    background: #ffa39e;
    border-color: #cf1322;
  }
  .ant-tag-green {
    color: black;
    background: #b7eb8f;
    border-color: #389e0d;
  }
  .ant-tag-orange {
    color: black;
  }

  .ant-tag-orangered {
    color: black;
  }

  .rais--timeline--black {
    .record-cell-valu {
      color: white;
    }
  }
}
