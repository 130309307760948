.dark-datepicker {
  background: #666666 !important;
  border: 1px dashed #d9d9dd;
  .ant-picker-input > input {
    color: white;
  }

  .ant-picker-clear {
    background: none !important;
  }
}
