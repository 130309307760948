.ant-progress-status-stand-by {
  .ant-progress-bg {
    background: orange;
  }
}

.ant-progress-status-stopped {
  .ant-progress-bg {
    background: orangered;
  }
}
