@import './rais--vis-timeline-item.common.less';

@planned-color: @blue-5;
@submitted-color: #36a8a0;
@expired-color: @red-7;
@on-hold-color: grey;

.vis-item {
  .events-timeline-item {
    &.planned {
      .events-timeline-item--base(@planned-color);
    }

    &.submitted {
      .events-timeline-item--base(@submitted-color);
    }

    &.expired {
      .events-timeline-item--base(@expired-color);
    }

    &.on-hold {
      .events-timeline-item--base(@on-hold-color);
    }
  }
}

.events-timeline-item__duration {
  &.expired {
    color: @red-7;
  }
}

.events-timeline-item__moment {
  &.expired {
    .events-timeline-item--label,
    .events-timeline-item--time {
      color: @red-7;
    }
  }
}
