@import 'rais--vis-timeline.common.less';
@import 'rais--vis-timeline.black.less';
@import 'rais--vis-timeline.white.less';
@import 'rais--vis-timeline.overrides.less';
@import 'styles/theme/colors';
@import './items/rais--vis-timeline-item';

@actual-event-row-height--1: inherit; // 60px; // !important;
@actual-event-row-height--2: inherit; // !important;
@actual-event-row-height--3: inherit; // !important;
@actual-event-row-height--4: inherit; // !important;
@actual-event-row-height--5: inherit; // !important;

.rais--timeline {
  .vis-timeline {
    border: none;
    visibility: inherit !important;
  }

  .vis-custom-time.current__ {
    pointer-events: none;
    background-color: #08979c;
    border-top: none;
  }

  .vis-custom-time.extraCorporealCirculationStartedAt {
    background-color: red;
  }

  .vis-custom-time.extraCorporealCirculationEndedAt {
    background-color: red;
  }

  .vis-custom-time {
    background-color: #1c92ff;
    pointer-events: none;
  }

  .vis-custom-time.disabled {
    pointer-events: none;
  }

  .max-width-group-content {
    .vis-inner {
      width: 100%;
    }
  }

  .bold {
    font-weight: 500;
  }

  .italic {
    font-style: italic;
  }

  .vis-panel {
    .vis-label.rais--actual-event-label {
      overflow-y: hidden;
      .vis-inner {
        // height: 100%;
      }
    }

    .vis-label.rais--actual-event-label--1 {
      height: @actual-event-row-height--1;
      overflow-y: hidden;
      .vis-inner {
        // height: 100%;
      }
    }

    .vis-label.rais--actual-event-label--2 {
      height: @actual-event-row-height--2;
      overflow-y: hidden;
      .vis-inner {
        // height: 100%;
      }
    }

    .vis-label.rais--actual-event-label--3 {
      height: @actual-event-row-height--3;
      overflow-y: hidden;
      .vis-inner {
        // height: 100%;
      }
    }

    .vis-label.rais--actual-event-label--4 {
      height: @actual-event-row-height--4;
      overflow-y: hidden;
      .vis-inner {
        // height: 100%;
      }
    }

    .vis-label.rais--actual-event-label--5 {
      height: @actual-event-row-height--5;
      overflow-y: hidden;
      .vis-inner {
        // height: 100%;
      }
    }

    .vis-background {
      .vis-group.rais--actual-event-label-1 {
        height: @actual-event-row-height--1;
      }
      .vis-group.rais--actual-event-label-2 {
        height: @actual-event-row-height--2;
      }
      .vis-group.rais--actual-event-label-3 {
        height: @actual-event-row-height--3;
      }
      .vis-group.rais--actual-event-label-4 {
        height: @actual-event-row-height--4;
      }
      .vis-group.rais--actual-event-label-5 {
        height: @actual-event-row-height--5;
      }
    }

    .vis-label.rais--parameter-label {
      // height: 33px !important;

      .vis-inner {
        // height: 100%;
      }
    }

    .vis-foreground {
      .vis-group.rais--actual-event-label--1 {
        height: @actual-event-row-height--1;
      }

      .vis-group.rais--actual-event-label--2 {
        height: @actual-event-row-height--2;
      }

      .vis-group.rais--actual-event-label--3 {
        height: @actual-event-row-height--3;
      }
      .vis-group.rais--actual-event-label--4 {
        height: @actual-event-row-height--4;
      }
      .vis-group.rais--actual-event-label--5 {
        height: @actual-event-row-height--5;
      }
    }

    .vis-group.rais--parameter-label {
      // height: 33px !important;
    }
  }

  .vis-panel.vis-left {
    .vis-group-level-0 {
      height: 35px;
    }
  }

  .vis-label.vis-nested-group .vis-inner {
    padding: 0;
  }
}

.rais--timeline {
  .vis-panel.vis-left {
    .vis-label.vis-group-level-0 {
      border-bottom-width: 2px;
      direction: rtl;

      .vis-inner {
        font-size: 13px;
        font-weight: bolder;
        font-variant-caps: titling-caps;
      }
    }

    .vis-label.vis-nesting-group {
      font-size: 12px;
      border-bottom-width: 2px;
      direction: rtl;

      .vis-inner {
        font-variant-caps: titling-caps;
      }
    }

    .vis-label.vis-nested-group {
      background: transparent !important;
    }
  }

  .vis-panel.vis-center {
    .rais--vis-group-separator.vis-group {
      // border-bottom-width: 2px;
    }
  }

  .rais--timeline-top-group-label {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .vis-data-axis .vis-y-axis.vis-minor {
    color: black;
  }
}

.without-top-panel .vis-top {
  display: none;
}

.record-cell-value {
  width: 100%;
  height: 20px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.24px;
}

.dosage-description {
  b {
    font-size: 11px;
  }
  font-style: italic;
  text-align: center;
  padding: 2px;
  color: #767f7f;
}

.prescription-button {
  border: none;
  background: #e0e1e1;
}

.prescription-sub-label {
  color: #767f7f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.prescription-main-label {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.vin-inner {
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.vis-legend {
  border-radius: 8px !important;
  border: none !important;
}

.design-button {
  border-color: #343d3c;
  color: #343d3c;
  background: transparent;
}
