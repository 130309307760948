.button__primary {
  background: #08979c;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px 16px;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.button__secondary {
  background: #e6f5f5;
  border-radius: 4px;
  color: #08979c;
  padding: 5px 16px;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  ::ng-deep * {
    cursor: pointer;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.ant-btn.button__success {
  background: darkgreen;
}

.ant-btn.button__start {
  border-color: #36A8A0;
  color: #36A8A0;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.ant-btn.button__stop {
  border-color: #A85C36;
  color: #A85C36;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.ant-btn.button__reset {
  border-color: #DD2922;
  color: #DD2922;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.ant-btn-primary:hover {
  color: #e6f5f5;
}

.dark-mode {
  .ant-select {
    color: #9e9e9e;

    .ant-select-selector {
      background-color: #262626;
      border: 1px dashed #d9d9dd;
    }
  }

  .ant-btn-primary {
    background: #595959;
    border-color: #595959;
  }

  .button__primary {
    background: #595959;
  }

  .ant-btn.button__success {
    background: darkgreen;
  }

  .ant-btn.button__start {
    border-color: #36A8A0;
    color: #36A8A0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.24px;
  }

  .ant-btn.button__stop {
    border-color: #A85C36;
    color: #A85C36;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.24px;
  }

  .ant-btn.button__reset {
    border-color: #DD2922;
    color: #DD2922;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.24px;
  }

  .ant-input {
    background: #262626;
    border: 1px dashed #d9d9dd;
    color: #9e9e9e;
  }

  .ant-input-number {
    background: #262626;
    border: 1px dashed #d9d9dd;
    color: #9e9e9e;
  }

  .ant-table {
    background: none;
  }
  .ant-table-small {
    border: none;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    color: #9e9e9e;
    background-color: transparent;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: none;
  }

  .ant-btn {
    background: #595959;
    border-color: #454545;
    &:hover {
      color: #36bdc1;
    }
    &.ant-btn-background-ghost {
      background: transparent;
    }
  }
}

.ant-btn-primary.dark-button {
  background: #595959;
  border-color: #595959;
  &:hover {
    color: #36bdc1;
  }
}

.dark-button.ant-btn-group {
  border-right-color: #080808;
  border-left-color: #080808;
}

.dark-button.ant-btn-group {
  & .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #080808;
    border-left-color: #080808;
  }

  & .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #080808;
  }

  & .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #080808;
  }
}

.add-button {
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }

  &[disabled] {
    cursor: not-allowed !important;
    pointer-events: none;
    color: gray !important;
    opacity: 0.7;
  }
}

.add-button--no-padding {
  .add-button.ant-btn {
    padding: 0;
  }
}
