.dark-select {
  /*
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: red;
  }
   */
  .ant-select {
    color: white;

    .ant-select-selector {
      background-color: #262626;
      border: 1px dashed #d9d9dd;
    }
  }
}

.ng-select .ng-select-container {
  min-height: 0;
  height: 25px;
}
.ng-select .ng-select-container .ng-value-container .ng-value {
  font-size: 13px;
}

.input-wrapper-select-width {
  display: flex;
  width: 100%;

  .ant-select {
    width: 100%;
  }
}

.disease-type-title-select {
  .ant-select {
    color: #000;
    font-size: 12px;
    font-style: normal;
  }

  .ant-select-selection-item {
    font-weight: 500;
  }
}

.collection-body-site-select .ant-select-selector {
  border-radius: 8px !important;
}

.biomaterial-type-select .ant-select-selector {
  border-radius: 8px !important;
}

.biomaterial-type-menu-select nz-select-item {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #000000;
}

.laboratory-page nz-select {
  border-radius: 8px;
  border: 1px solid #d4e2e1;
  background: #fff;
  width: 100%;
  display: flex;
  padding: 2px;
}

.laboratory-page nz-select-top-control {
    border: none !important;
    height: 26px !important;
    padding-top: 3px !important;
}