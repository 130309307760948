@record-text-color: black;
@record-inner-borders-color: #c1c1c1;
@record-row-label-color: black;

.observation-extra-container {
  display: flex;
}

.observation {
  .vis-panel.vis-center {
    // border: 1px solid @record-inner-borders-color;
  }
  .vis-panel.vis-left {
    border: 1px solid @record-inner-borders-color;
    border-right: none;
    box-shadow: 1px 0px 5px 0px rgb(140 140 140 / 61%);
    // min-width: 200px;
  }
  .vis-panel.vis-top {
    border-left: 1px solid @record-inner-borders-color;
  }

  .vis-labelset .vis-label {
    border-bottom: 1px solid @record-inner-borders-color;
  }

  .vis-foreground {
    .vis-group {
      border-bottom: 1px dashed @record-inner-borders-color;
    }
  }
  .vis-time-axis {
    color: #8c8c8c !important;
    opacity: 0.6;
    .vis-grid.vis-minor {
      border-left: 1px dashed @record-inner-borders-color;
    }
    .vis-grid.vis-major {
      border-left: 1px solid @record-inner-borders-color;
    }
    .vis-text {
      color: @record-text-color;
    }
  }

  .vis-horizontal {
    .vis-grid.vis-minor {
      // border: 1px dashed @record-inner-borders-color !important;
      border: 1px dotted #bfbfbf38 !important;
    }
    .vis-grid.vis-major {
      // border: 1px dashed @record-inner-borders-color !important;
      border: 1px dotted #bfbfbf38 !important;
    }
  }
  .vis-labelset .vis-inner {
    color: @record-row-label-color;
    width: 100%;
  }

  .collapsed {
    .vis-inner {
      width: auto !important;
    }
  }

  .vis-x-axis {
    color: @record-text-color;
    opacity: 0.9;
  }

  .vis-y-axis {
    color: @record-text-color;
    opacity: 0.9;
  }

  rais-chart {
    .vis-left {
      .vis-content {
        background: black !important;
      }
    }
  }

  rais-prescription-timeline-item {
    .horizontal * {
      color: black !important;
    }

    .prescription--volume {
      overflow: hidden;
    }
  }

  .vis-legend {
    background-color: #434343;
    box-shadow: none;

    .vis-legend-text {
      color: white;
    }
  }
  .without-timeline rect {
    opacity: 0;
  }
}
