.process-sidebar {
  &.hidden {
    display: none;
  }
  height: 100%;
  padding: 10px 8px;
  box-shadow: -1px 0px 5px 1px #aaaaaa6b;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;

  .no-charts-label {
    color: #ffffff;
  }
  .info--date {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
  }

  .info--title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-left: 5px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 1440px) {
  .process-sidebar1 {
    position: absolute;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 3;
  }
}

.sidebar-section {
  border-bottom: 1px solid var(--divider, #d4e2e1);
}
