@import './colors';
.ant-notification-notice {
  background: @rais--general-black;
  border-radius: 16px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  color: @rais--general-white;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  color: @rais--general-white;
}

.ant-notification-notice-close {
  color: @rais--general-white;
}
