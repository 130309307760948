.elements-groups-collapse {
  .ant-collapse-header {
    background: var(--white-on-white-20, #f5f5f5);
    border-radius: 8px;
    font-weight: 600;
    line-height: normal;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.attributes-panel {
  .ant-collapse-header {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    background: rgba(54, 168, 160, 0.20);;
  }

  .ant-collapse-arrow {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .custom-input--wrapper {
    .prefix {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #F5F5F5;
        border-radius: 4px;
      }
    }
  }
}
