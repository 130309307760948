.insertions-sidebar {
  &.hidden {
    display: none;
  }
  height: 100%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 328px;

  .ant-collapse-header {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    width: 280px;
  }

  .ant-collapse-arrow {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .items-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000000d9;
    padding: 16px 8px;
    width: 280px;
  }
}
