.nz-input-number--align-center {
  .ant-input-number-input {
    text-align: center !important;
  }
}

.nz-input-number--border-none {
  .ant-input-number {
    border: none !important;
  }
}
